import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, GuardsCheckEnd, NavigationStart, Router } from '@angular/router';
import config from '@config';
import { AuthService } from '@services/auth.service';
import { EtlRoutesStore } from '@services/etl-routes.store';
import { FeatureFlagsService } from '@services/feature-flags.service';
import { PharmaciesSearchStore } from '@services/pharmacies.search.store';
import { SettingsStore } from '@services/settings.store';
import { TenantService } from '@services/tenant.service';
import { UIAnalyticsService } from '@services/ui-analytics.service';
import { UserService } from '@services/user.service';
import { Amplify } from 'aws-amplify';
import { Observable, forkJoin, timer } from 'rxjs';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.main.html',
})
export class AppComponent {
  private isActiveWindow = true;
  private readonly stores = {
    etlRoutes: inject(EtlRoutesStore),
    pharmacies: inject(PharmaciesSearchStore),
    settings: inject(SettingsStore),
  };

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private tenantService: TenantService,
    private uiAnalyticsService: UIAnalyticsService,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.initAmplify();
    this.trackAuthStatus();
    this.trackPageChange();
    this.trackWindowState();
    this.trackLocalStorage();
    this.trackStores();
    this.trackTenantChange();
    this.trackFeatureFlags();
  }

  private initAmplify() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: config.cognito.userpoolId,
          userPoolClientId: config.cognito.appClientId,
        },
      },
    });
  }

  private trackWindowState(): void {
    window.addEventListener('blur', () => {
      this.isActiveWindow = false;
    });
    window.addEventListener('focus', () => {
      this.isActiveWindow = true;
    });
  }

  private trackLocalStorage(): void {
    window.addEventListener('storage', event => {
      if (event.storageArea === localStorage) {
        const logout = localStorage.getItem(this.authService.LOGOUT_INITIATED);
        if (logout === this.authService.LOGOUT_INITIATED_VALUE && !this.isActiveWindow) {
          this.router.navigate(['/login']);
        }
      }
    });
  }

  private trackPageChange(): void {
    this.router.events.pipe(takeUntilDestroyed()).subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.router.url !== event.url) this.uiAnalyticsService.trackRedirect(event.url);
      } else if (event instanceof GuardsCheckEnd) {
        if (!event.shouldActivate) {
          this.authService.logout('AuthGuardCheck');
        }
      }
    });
  }

  private trackAuthStatus(): void {
    this.authService.authStatusChanged.pipe(takeUntilDestroyed(), distinctUntilChanged()).subscribe(authenticated => {
      if (authenticated) {
        const user = this.authService.getCurrentUser();
        this.setTenantSpecificPages();
        if (!this.userService.hasAcceptedAgreements(user)) {
          this.router.navigate(['/agreement']);
        } else {
          this.authService.redirectToStartingPage();
        }
      }
    });
  }

  private setTenantSpecificPages(): void {
    if (this.tenantService.usersTenants.length > 0) {
      this.tenantService.changeCurrentTenant(this.tenantService.usersTenants[0]);
    }
  }

  private trackTenantChange() {
    this.tenantService.activeTenantChanged.subscribe(() => {
      if (this.userService.isComponentAllowedForRoute(this.activatedRoute) === false) {
        this.authService.redirectToStartingPage();
      }
    });
  }

  private trackStores() {
    const fiveMinutes = 5 * 60 * 1000;
    timer(fiveMinutes, fiveMinutes)
      .pipe(
        switchMap(() => {
          const sources$: Array<Observable<{}>> = [];
          sources$.push(this.stores.etlRoutes.refresh());
          sources$.push(this.stores.pharmacies.refresh());
          sources$.push(this.stores.settings.refresh());
          return forkJoin(sources$);
        }),
      )
      .subscribe(() => {
        console.debug('Stores refresh done');
      });
  }

  private trackFeatureFlags() {
    const fiveMinutes = 5 * 60 * 1000;
    timer(fiveMinutes, fiveMinutes)
      .pipe(tap(() => this.featureFlagsService.reload()))
      .subscribe(() => {
        console.debug('Feature flags refresh done');
      });
  }
}
