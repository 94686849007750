import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DialogService } from '@services/dialog.service';
import { MessageModalComponent } from '@shared/components/message-modal/message-modal.component';
import noInternet from 'no-internet';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RetryInterceptor implements HttpInterceptor {
  readonly RETRY_COUNT = 2;
  readonly RETRY_WAIT_MILLIS = 1000;
  readonly ERROR_STATUS = 500;
  readonly dialogService = inject(DialogService);

  intercept(request: HttpRequest<{}>, next: HttpHandler): Observable<HttpEvent<{}>> {
    return next.handle(request).pipe(
      retryWhen((errors: Observable<HttpErrorResponse>) =>
        errors.pipe(
          concatMap((error, count) => {
            if (count <= this.RETRY_COUNT && error.status >= this.ERROR_STATUS) {
              return of(error);
            }
            this.checkInternet();
            return throwError(error);
          }),
          delay(this.RETRY_WAIT_MILLIS),
        ),
      ),
    );
  }

  private checkInternet() {
    noInternet().then((offline: boolean) => {
      if (offline) {
        const dialog = this.dialogService.openModal(MessageModalComponent, {
          backdrop: 'static',
          centered: true,
          size: 'lg',
        });
        dialog.componentInstance.message = 'Please check your internet connection and try again.';
      }
    });
  }
}
