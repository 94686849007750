import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { injectParams } from 'ngxtension/inject-params';

@Component({
  selector: 'app-terminal-entry',
  template: '',
})
export class TerminalEntryComponent implements OnInit {
  private token = injectParams('token');
  private router = inject(Router);

  ngOnInit() {
    if (!this.token()) {
      this.router.navigate(['/terminal/error']);
    } else {
      this.router.navigate(['/terminal/', this.token()?.toLowerCase()]);
    }
  }
}
