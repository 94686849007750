import { inject, Injectable } from '@angular/core';
import config from '@config';
import { FeatureFlagsService } from '@services/feature-flags.service';

@Injectable({ providedIn: 'root' })
export class AppService {
  readonly featureFlagsService = inject(FeatureFlagsService);

  async checkVersion() {
    await this.featureFlagsService.reload();
    const apiVersion = this.featureFlagsService.getStringValue('app-version');
    const isVersionTheSame = apiVersion === config.version;
    if (!apiVersion) {
      console.warn('API version not found');
    } else if (!isVersionTheSame) {
      console.log('Version different. Refreshing');
      console.log('API version: ', apiVersion);
      console.log('ENV version: ', config.version);
      window.location.reload();
    }
  }
}
